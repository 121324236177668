import request from '@/utils/request'

export function getNodeData () {
  return request({
    url: '/node/nodeData',
    method: 'get'
  })
}

export function updateNode (editData) {
  const data = {
    editData
  }
  return request({
    url: '/node/updateNode',
    method: 'post',
    data
  })
}

export function setNodeLocation (locationData) {
  const data = {
    locationData
  }
  return request({
    url: '/node/setNodeLocation',
    method: 'post',
    data
  })
}
