<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary" >
          <div slot="header">
            <i class="icon-list" style="margin-right: .5rem;"></i>
            <strong>用戶管理</strong>
          </div>
          <b-row>
            <b-col cols="8" sm="8" md="10">
              <b-select v-model="searchData" :options="distributorOptions" @change="getUserData()"></b-select>
            </b-col>
            <b-col cols="4" sm="4" md="2" class="text-center">
              <b-button class="action-btn" variant="primary" @click="createUser()">新增用戶</b-button>
            </b-col>
          </b-row>
          <hr>
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <div slot="action" class="text-center" slot-scope="props">
                <b-button class="action-btn" variant="outline-warning" @click="editUser(props.row)">編輯</b-button>
                <b-button class="action-btn" variant="outline-danger" @click="deleteUser(props.row)">刪除</b-button>
              </div>
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal v-model="createUserModal" title="新增用戶" header-bg-variant="primary" ok-only no-stacking>
      <b-form-group
        label="歸屬廠商"
        label-for="did"
        :label-cols="3"
        :invalid-feedback="invalidDid"
        :state="stateDid">
        <b-form-select id="did" v-model="createData.did" :state="stateDid" :options="distributorOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="帳號"
        label-for="account"
        :label-cols="3"
        :invalid-feedback="invalidAccount"
        :state="stateAccount">
        <b-form-input id="account" type="text" v-model="createData.account" :state="stateAccount" trim placeholder="- account"></b-form-input>
      </b-form-group>
      <b-form-group
        label="密碼"
        label-for="passwd"
        :label-cols="3"
        :invalid-feedback="invalidPasswd"
        :state="statePasswd">
        <b-input-group>
          <b-form-input id="passwd" type="text" v-model="createData.passwd" :state="statePasswd" trim placeholder="- passwd" disabled></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-primary" @click="random_password()">
              <i class="fa fa-undo"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="權限"
        label-for="roles"
        :label-cols="3"
        :invalid-feedback="invalidRoles"
        :state="stateRoles">
        <b-form-select id="roles" v-model="createData.roles" :state="stateRoles" :options="rolesOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="暱稱"
        label-for="displayName"
        :label-cols="3">
        <b-form-input id="displayName" type="text" v-model="createData.displayName" trim placeholder="- displayName"></b-form-input>
      </b-form-group>
      <b-form-group
        label="聯絡電話"
        label-for="phone"
        :label-cols="3">
        <b-form-input id="phone" type="text" v-model="createData.phone" trim placeholder="- phone"></b-form-input>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="createUserModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="storeUser()">確定</b-button>
    </b-modal>

    <b-modal v-model="editUserModal" title="編輯用戶" header-bg-variant="warning" ok-only>
      <b-form-group
        label="歸屬廠商"
        label-for="did"
        :label-cols="3">
        <b-form-select id="did" v-model="editData.did" :options="distributorOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="帳號"
        label-for="account"
        :label-cols="3">
        <b-form-input id="account" type="text" v-model="editData.account" trim placeholder="- account" disabled></b-form-input>
      </b-form-group>
      <b-form-group
        label="密碼"
        label-for="passwd"
        :label-cols="3">
        <b-input-group>
          <b-form-input id="passwd" type="text" v-model="editData.passwd" trim placeholder="- passwd" disabled></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-primary" @click="random_password()">
              <i class="fa fa-undo"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="權限"
        label-for="roles"
        :label-cols="3">
        <b-form-select id="roles" v-model="editData.roles" :options="rolesOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="暱稱"
        label-for="displayName"
        :label-cols="3">
        <b-form-input id="displayName" type="text" v-model="editData.displayName" trim placeholder="- displayName"></b-form-input>
      </b-form-group>
      <b-form-group
        label="聯絡電話"
        label-for="phone"
        :label-cols="3">
        <b-form-input id="phone" type="text" v-model="editData.phone" trim placeholder="- phone"></b-form-input>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="editUserModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="warning" @click="updateUser()">確定</b-button>
    </b-modal>

    <b-modal v-model="deleteUserModal" title="刪除用戶" header-bg-variant="danger" ok-only no-stacking>
      <h6>您確定要刪除 <font v-text="deleteData.account"></font> ?</h6>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="deleteUserModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="danger" @click="destroyUser()">確定</b-button>
    </b-modal>

  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: .25rem!important;
}
.VueTables__limit-field select {
  margin-left: .25rem!important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
export default {
  name: 'adminUser',
  components: {
  },
  data: function () {
    return {
      searchData: '',
      distributorOptions: [{value: '', text: '請選擇....', disabled: true}, {value: null, text: '無歸屬廠商'}],
      rolesOptions: [
        {value: '', text: '請選擇....', disabled: true},
        {value: 'admin', text: 'Admin'},
        {value: 'vendor', text: 'Vendor'}
      ],
      createUserModal: false,
      createData: { account: '', passwd: '', roles: '', did: '', displayName: '', phone: '' },
      editUserModal: false,
      editData: {},
      deleteUserModal: false,
      deleteData: {},
      columns: [ 'account', 'roles', 'displayName', 'phone', 'action' ],
      data: [],
      options: {
        headings: {
          account: '帳號',
          roles: '權限',
          displayName: '暱稱',
          phone: '聯絡電話',
          action: '動作'
        },
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: [ 'account', 'roles', 'displayName', 'phone', 'action' ],
        filterable: [ 'account', 'roles', 'displayName', 'phone', 'action' ]
      }
    }
  },
  computed: {
    stateDid () {
      return this.createData.did !== ''
    },
    invalidDid () {
      return this.createData.did !== '' ? '' : '必填欄位不得為空'
    },
    stateAccount () {
      return this.createData.account.length > 0
    },
    invalidAccount () {
      return this.createData.account.length > 0 ? '' : '必填欄位不得為空'
    },
    statePasswd () {
      return this.createData.passwd.length > 0
    },
    invalidPasswd () {
      return this.createData.passwd.length > 0 ? '' : '必填欄位不得為空'
    },
    stateRoles () {
      return this.createData.roles.length > 0
    },
    invalidRoles () {
      return this.createData.roles.length > 0 ? '' : '必填欄位不得為空'
    }
  },
  mounted: function () {
    this.getDistributorData()
  },
  methods: {
    random_password () {
      let random = Math.floor(Math.random() * 9) + 1 // random 1~9
      for (var i = 0; i < 5; i++) {
        random = random.toString() + Math.floor(Math.random() * 10).toString() // random 0~9
      }
      this.createData.passwd = random
      this.editData.passwd = random
    },
    getDistributorData () {
      this.$store.dispatch('getDistributorData').then(res => {
        for (let i in res) {
          this.distributorOptions.push({
            value: res[i].did,
            text: res[i].distributorName
          })
        }
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    getUserData () {
      this.$store.dispatch('getUserData', {did: this.searchData}).then(res => {
        this.data = res
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    createUser () {
      this.createData = { account: '', passwd: '', roles: '', did: '', displayName: '', phone: '' }
      this.createUserModal = true
    },
    storeUser () {
      if (this.stateDid && this.stateAccount && this.statePasswd && this.stateRoles) {
        this.$store.dispatch('storeUser', this.createData).then(res => {
          this.createUserModal = false
          if (this.createData.did === this.searchData) this.getUserData()
          this.$swal('Success', '用戶新增成功', 'success')
        }).catch(e => {
          this.$swal('Error', e, 'error')
        })
      }
    },
    editUser (prop) {
      this.editData = { account: prop.account, passwd: null, roles: prop.roles, did: prop.did, displayName: prop.displayName, phone: prop.phone }
      this.editUserModal = true
    },
    updateUser () {
      this.$store.dispatch('updateUser', this.editData).then(res => {
        this.editUserModal = false
        this.getUserData()
        this.$swal('Success', '用戶更新成功', 'success')
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    deleteUser (prop) {
      this.deleteData = prop
      this.deleteUserModal = true
    },
    destroyUser () {
      this.$store.dispatch('destroyUser', {auid: this.deleteData.auid}).then(res => {
        this.deleteUserModal = false
        this.getUserData()
        this.$swal('Success', '用戶刪除成功', 'success')
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    }
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
