export default {
  items: [
    {
      name: '儀表板',
      class: '',
      url: '/dashboard',
      icon: 'icon-speedometer',
      roles: ['admin', 'vendor']
    },
    {
      title: true,
      name: '系統維運',
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      },
      roles: ['admin']
    },
    {
      name: '車鎖管理',
      url: '/manage/lock',
      icon: 'icon-list',
      roles: ['admin']
    },
    {
      name: '車位管理',
      url: '/manage/space',
      icon: 'icon-list',
      roles: ['admin']
    },
    {
      name: '節點機管理',
      url: '/manage/node',
      icon: 'icon-list',
      roles: ['admin']
    }
  ]
}
