<template>
  <div class="loginPage app flex-row align-items-center">
    <div class="container" style="margin-left: 4rem;">
      <b-row>
        <b-col md="4">
                <b-form @submit.prevent="login">
                  <h1>Locker Manager System</h1>
                  <p class="text-muted">{{$t('loginUsername')}}</p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input type="text" v-model="username" class="form-control" placeholder="Username" autocomplete="username email" />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input type="password" v-model="password" class="form-control" placeholder="Password" autocomplete="current-password" />
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button type="submit" variant="primary" class="px-4" >{{$t('login')}}</b-button>
                    </b-col>
                    <b-col cols="6">
                      <b-select v-model="selectLanguage" @change="switchLocale">
                        <option :value="defaultLanguage">繁體中文</option>
                        <option v-for="lan in selectLanguageOptions" :key="lan.value" :value="lan.value" :disabled="lan.disabled">{{ lan.text }}</option>
                      </b-select>
                    </b-col>
                  </b-row>
                </b-form>
        </b-col>
      </b-row>
      <loading
        :show="show"
        :label="label"
        :overlay="overlay">
      <GridLoader slot="loading-spinner"
                  color="#f5a623"
                  :size=10
                  style="display:inline-block;"
                  >
      </GridLoader>
      </loading>
    </div>
    <img id="loginLog" src="img/brand/logo.svg">
  </div>
</template>
<style>
.loginPage {
  background-image: url('../../../public/img/login.png'),
                    linear-gradient(to right, white 40%, #bad6df 40%);
  background-position: 95% 90%,
                       right;
  background-size: 50%, contain;
  background-repeat: no-repeat;
  animation: mymove 3s;
}
#loginLog {
  width: 7rem;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
}
@keyframes mymove {
  from {
    background-position: 95% 150%;
  }
  to {
    background-position: 95% 90%;
  }
}
</style>

<script>
import loading from 'vue-full-loading'

export default {
  name: 'Login',
  components: {
    loading
  },
  data () {
    return {
      show: false,
      label: 'Loading...',
      overlay: true,
      username: '',
      password: '',
      disableLanguageSelect: true,
      defaultLanguage: 'tw',
      selectLanguage: 'tw',
      selectLanguageOptions: [
        { value: 'cn', text: '简体中文', disabled: true },
        { value: 'en', text: 'English', disabled: false },
        { value: 'jp', text: '日本語', disabled: true }
      ]
    }
  },
  methods: {
    login () {
      this.show = true
      let loginData = {
        username: this.username,
        password: this.password
      }
      this.$store.dispatch('LoginByUsername', loginData).then(() => {
        this.show = false
        this.$router.push({ path: this.redirect || '/' })
      }).catch((e) => {
        this.show = false
        this.$swal('Error', e, 'error')
      })
    }
  }
}
</script>
