import router from './router'
import store from './store'
import { getToken } from '@/utils/auth' // getToken from cookie

const whiteList = ['/login', '/auth-redirect']// no redirect whitelist

router.beforeEach((to, from, next) => {
  if (getToken()) { // determine if there has token
    /* has token */
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      if (store.getters.roles.length === 0) { // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetUserRoles').then(res => { // 拉取user_info
          if (to.meta.roles.includes(store.getters.roles)) {
            next()
          } else {
            alert('權限不足')
            next({ path: '/' })
          }
        }).catch((err) => {
          store.dispatch('FedLogOut').then(() => {
            console.error(err || 'Verification failed, please login again')
            next({ path: '/' })
          })
        })
      } else {
        if (to.meta.roles.includes(store.getters.roles)) {
          next()
        } else {
          alert('權限不足')
          next({ path: '/' })
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      next()
    } else {
      next('/login') // 否则全部重定向到登录页
    }
  }
})

router.afterEach(() => {

})
