import * as spaceApi from '@/api/space'

const space = {
  state: {

  },
  mutations: {

  },
  actions: {
    getSpaceData ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        spaceApi.getSpaceData(condition).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getBindList ({ commit }, condition) {
      return new Promise((resolve, reject) => {
        spaceApi.getBindList(condition).then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    storeSpace ({ commit }, createData) {
      return new Promise((resolve, reject) => {
        spaceApi.storeSpace(createData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    updateSpace ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        spaceApi.updateSpace(editData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    destroySpace ({ commit }, deleteData) {
      return new Promise((resolve, reject) => {
        spaceApi.destroySpace(deleteData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    setSpaceLocation ({ commit }, locationData) {
      return new Promise((resolve, reject) => {
        spaceApi.setSpaceLocation(locationData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    }

  }
}

export default space
