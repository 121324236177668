import request from '@/utils/request'

export function getLockData (condition) {
  return request({
    url: '/lock/lockData',
    method: 'get',
    params: condition
  })
}

export function updateLock (editData) {
  const data = {
    editData
  }
  return request({
    url: '/lock/updateLock',
    method: 'post',
    data
  })
}
