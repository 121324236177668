const getters = {
  // sidebar: state => state.app.sidebar,
  // language: state => state.app.language,
  // size: state => state.app.size,
  // device: state => state.app.device,
  // visitedViews: state => state.tagsView.visitedViews,
  // cachedViews: state => state.tagsView.cachedViews,
  token: state => state.admin.token,
  avatar: state => state.admin.avatar,
  name: state => state.admin.name,
  introduction: state => state.admin.introduction,
  roles: state => state.admin.roles,
  vendor: state => state.admin.vendor,
  setting: state => state.admin.setting
  // errorLogs: state => state.errorLog.logs
}
export default getters
