import * as nodeApi from '@/api/node'

const node = {
  actions: {
    getNodeData ({ commit }) {
      return new Promise((resolve, reject) => {
        nodeApi.getNodeData().then(res => {
          if (res.code === 2001) {
            resolve(res.data)
          } else {
            reject(res.code)
          }
        })
      })
    },

    updateNode ({commit}, editData) {
      return new Promise((resolve, reject) => {
        nodeApi.updateNode(editData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    },

    setNodeLocation ({ commit }, locationData) {
      return new Promise((resolve, reject) => {
        nodeApi.setNodeLocation(locationData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    }

  }
}

export default node
