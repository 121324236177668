import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/login/login'
import Dashboard from '@/views/dashboard/dashboard'
import DefaultContainer from '@/containers/DefaultContainer'
import adminUser from '@/views/adminUser/adminUser'
import Distributor from '@/views/distributor/distributor'
import space from '@/views/space/space'
import lock from '@/views/lock/lock'
import node from '@/views/node/node'
import personal from '@/views/adminUser/personal'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/Login',
      name: 'Login',
      components: {
        default: Login
      },
      meta: {
        roles: ['admin', 'vendor', 'tester']
      }
    },
    {
      path: '/',
      name: 'Home',
      redirect: '/dashboard',
      component: DefaultContainer,
      children: [
        {
          path: 'dashboard',
          name: '儀表板',
          component: Dashboard,
          meta: {
            roles: ['admin', 'vendor', 'tester']
          }
        },
        {
          path: 'distributor',
          name: '廠商管理',
          component: Distributor,
          meta: {
            roles: ['admin']
          }
        },
        {
          path: 'adminUser',
          name: '用戶管理',
          component: adminUser,
          meta: {
            roles: ['admin']
          }
        },
        {
          path: 'personal',
          name: '個人資料',
          component: personal,
          meta: {
            roles: ['admin']
          }
        },
        {
          path: 'manage',
          redirect: '/manage/lock',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'lock',
              name: '車鎖管理',
              component: lock,
              meta: {
                roles: ['admin']
              }
            },
            {
              path: 'space',
              name: '車位管理',
              component: space,
              meta: {
                roles: ['admin']
              }
            },
            {
              path: 'node',
              name: '節點機管理',
              component: node,
              meta: {
                roles: ['admin']
              }
            }
          ]
        }
      ]
    }
  ]
})
