<template>
  <b-tabs>
    <b-tab>
      <template slot="title">
        <i class='icon-list'></i>
      </template>
      <b-list-group class="list-group-accent">
        <b-list-group-item v-show="todayEvents.length !== 0" class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">
          今天
        </b-list-group-item>
        <template v-for="event in todayEvents">
          <b-list-group-item href="#/calendar" class="list-group-item-divider" :key="event.id" :class="changeClass(event.color)">
            <div><strong> {{ event.title }} </strong>
            </div>
            <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp;&nbsp; {{ event.time }} </small>
            <small class="text-muted"><i class="icon-direction"></i>&nbsp;&nbsp;{{ event.type }}</small>
          </b-list-group-item>
        </template>
        <hr class="transparent mx-3 my-0">
        <b-list-group-item v-show="tomorrowEvents.length !== 0" class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">
          明天
        </b-list-group-item>
        <template v-for="event in tomorrowEvents">
          <b-list-group-item href="#/calendar" class="list-group-item-divider" :key="event.id" :class="changeClass(event.color)">
            <div><strong> {{ event.title }} </strong>
            </div>
            <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp;&nbsp; {{ event.time }} </small>
            <small class="text-muted"><i class="icon-direction"></i>&nbsp;&nbsp;{{ event.type }}</small>
          </b-list-group-item>
        </template>
        <hr class="transparent mx-3 my-0">
        <b-list-group-item v-show="monthEvents.length !== 0" class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">
          一個月內
        </b-list-group-item>
        <template v-for="event in orderMonthEvents">
          <b-list-group-item href="#/calendar" class="list-group-item-divider" :key="event.id" :class="changeClass(event.color)">
            <div> {{ event.date }} <strong> {{ event.title }} </strong>
            </div>
            <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp;&nbsp; {{ event.time }} </small>
            <small class="text-muted"><i class="icon-direction"></i>&nbsp;&nbsp;{{ event.type }}</small>
          </b-list-group-item>
        </template>
      </b-list-group>
    </b-tab>
    <!-- <b-tab>
      <template slot="title">
        <i class='icon-settings'></i>
      </template>
      <div class="p-3">
        <h6>Settings</h6>
        <div class="aside-options">
          <div class="clearfix mt-4">
            <small><b>Option 1</b></small>
            <c-switch color="success" label variant="pill" size="sm" class="float-right" checked />
          </div>
          <div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small>
          </div>
        </div>
        <div class="aside-options">
          <div class="clearfix mt-3">
            <small><b>Option 2</b></small>
            <c-switch color="success" label variant="pill" size="sm" class="float-right" />
          </div>
          <div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small>
          </div>
        </div>
        <div class="aside-options">
          <div class="clearfix mt-3">
            <small><b>Option 3</b></small>
            <c-switch color="success" label variant="pill" size="sm" class="float-right" disabled defaultChecked/>
          </div>
          <div>
            <small class="text-muted">Disabled option.</small>
          </div>
        </div>
        <div class="aside-options">
          <div class="clearfix mt-3">
            <small><b>Option 4</b></small>
            <c-switch color="success" label variant="pill" size="sm" class="float-right" checked />
          </div>
        </div>
        <hr>
        <h6>System Utilization</h6>
        <div class="text-uppercase mb-1 mt-4">
          <small><b>CPU Usage</b></small>
        </div>
        <b-progress height={} class="progress-xs" variant="info" :value="25"></b-progress>
        <small class="text-muted">348 Processes. 1/4 Cores.</small>
        <div class="text-uppercase mb-1 mt-2">
          <small><b>Memory Usage</b></small>
        </div>
        <b-progress height={} class="progress-xs" variant="warning" :value="70"></b-progress>
        <small class="text-muted">11444GB/16384MB</small>
        <div class="text-uppercase mb-1 mt-2">
          <small><b>SSD 1 Usage</b></small>
        </div>
        <b-progress height={} class="progress-xs" variant="danger" :value="95"></b-progress>
        <small class="text-muted">243GB/256GB</small>
        <div class="text-uppercase mb-1 mt-2">
          <small><b>SSD 2 Usage</b></small>
        </div>
        <b-progress height={} class="progress-xs" variant="success" :value="10"></b-progress>
        <small class="text-muted">25GB/256GB</small>
      </div>
    </b-tab> -->
  </b-tabs>
</template>

<script>
// import { Switch as cSwitch } from '@coreui/vue'
let _ = require('lodash')
export default {
  name: 'DefaultAside',
  components: {
    // cSwitch
  },
  data: function () {
    return {
      type: [
        { id: 1, text: '場域到期日' },
        { id: 99, text: '其他' },
        { id: 0, text: '系統記錄' }
      ],
      itemColor: 'list-group-item-accent-success',
      todayEvents: [],
      tomorrowEvents: [],
      monthEvents: []
    }
  },
  computed: {
    orderMonthEvents () {
      return _.orderBy(this.monthEvents, 'date')
    }
  },
  mounted () {
    this.showEvent()
  },
  methods: {
    showEvent () {
      this.todayEvents = []
      this.tomorrowEvents = []
      this.monthEvents = []
      let firstDate = this.$moment().startOf('day').valueOf()
      let endDate = this.$moment().add(30, 'd').valueOf()
      this.$store.dispatch('getReminderData', { startAt: firstDate, endAt: endDate }).then((res) => {
        for (let i in res) {
          let event = []
          let allDay = 'all Day'
          if (res[i].reminderType === 0) {
            allDay = this.$moment(res[i].reminderTime).format('hh:mm')
          }
          event = {
            id: res[i].rID,
            title: res[i].reminderTitle,
            time: allDay,
            date: this.$moment(res[i].reminderTime).format('MM-DD'),
            color: res[i].reminderType,
            type: this.type.filter(type => type.id === res[i].reminderType)[0].text
          }
          switch (event.date) {
            case (this.$moment().format('MM-DD')):
              this.todayEvents.push(event)
              break
            case (this.$moment().add(1, 'd').format('MM-DD')):
              this.tomorrowEvents.push(event)
              break
            default:
              this.monthEvents.push(event)
              break
          }
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    changeClass (color) {
      switch (color) {
        case 99:
          return 'list-group-item-accent-success'
        case 1:
          return 'list-group-item-accent-primary'
        case 0:
          return 'list-group-item-accent-danger'
      }
    }
  }
}
</script>
