import request from '@/utils/request'

export function getDistributorData () {
  return request({
    url: '/distributor/distributorData',
    method: 'get'
  })
}

export function storeDistributor (createData) {
  const data = {
    createData
  }
  return request({
    url: 'distributor/storeDistributor',
    method: 'post',
    data
  })
}

export function updateDistributor (editData) {
  const data = {
    editData
  }
  return request({
    url: 'distributor/updateDistributor',
    method: 'post',
    data
  })
}

export function destroyDistributor (deleteData) {
  const data = {
    deleteData
  }
  return request({
    url: 'distributor/destroyDistributor',
    method: 'post',
    data
  })
}

export function setDistributorLocation (locationData) {
  const data = {
    locationData
  }
  return request({
    url: 'distributor/setDistributorLocation',
    method: 'post',
    data
  })
}

export function getSdkData (did) {
  return request({
    url: 'distributor/sdkData',
    method: 'get',
    params: did
  })
}

export function storeSdk (createSdkData) {
  const data = {
    createSdkData
  }
  return request({
    url: 'distributor/storeSdk',
    method: 'post',
    data
  })
}

export function updateSdk (editSdkData) {
  const data = {
    editSdkData
  }
  return request({
    url: 'distributor/updateSdk',
    method: 'post',
    data
  })
}

export function destroySdk (deleteSdkData) {
  const data = {
    deleteSdkData
  }
  return request({
    url: 'distributor/destroySdk',
    method: 'post',
    data
  })
}

