<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col cols="12" sm="6" md="3" lg="3">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <i class="fa fa-building bg-primary p-3 font-2xl mr-3 float-left"></i>
            <div class="h5 text-primary mb-0 mt-2">{{ mainData.distributorCount }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">營運廠商數</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" md="3" lg="3">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <i class="fa fa-bicycle bg-success p-3 font-2xl mr-3 float-left"></i>
            <div class="h5 text-success mb-0 mt-2">{{ mainData.bikeCount }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">營運車輛數</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" md="3" lg="3">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <i class="fa fa-product-hunt bg-danger p-3 font-2xl mr-3 float-left"></i>
            <div class="h5 text-danger mb-0 mt-2">{{ mainData.spaceCount }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">營運車位數</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" md="3" lg="3">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <i class="fa fa-users bg-warning p-3 font-2xl mr-3 float-left"></i>
            <div class="h5 text-warning mb-0 mt-2">{{ mainData.userCount }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">會員總數</div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="12">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <b-card-title>訂單總數</b-card-title>
            <div ref="chartWrap">
              <ve-line :data="chartData" :settings="chartSetting" :extend="extend" ref="chart"></ve-line>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="12">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <b-card-title>車位分佈</b-card-title>
            <div>
              <GmapMap class="map setLocationMap" :center="locationData.center" :zoom="locationData.zoom" style="width: 100%; height: 400px;">
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in markList"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :visible="m.visible"
                  @click="toggleInfoWindow(m, index)"/>
                <gmap-info-window
                  :options="infoOptions"
                  :position="infoWindowPos"
                  :opened="infoWinOpen"
                  @closeclick="infoWinOpen=false">
                  <div v-html="infoContent"></div>
                </gmap-info-window>
              </GmapMap>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<style>

.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: .25rem!important;
}
.VueTables__limit-field select {
  margin-left: .25rem!important;
}
</style>

<script>
import elementResizeDetectorMaker from 'element-resize-detector'

export default {
  name: 'dashboard',
  components: {
  },
  data: function () {
    return {
      mainData: { distributorCount: 0, bikeCount: 0, spaceCount: 0, userCount: 0 },
      chartSetting: {
        labelMap: {
          storage: '保管車',
          rent: '租借車'
        }
      },
      extend: {
        'xAxis.0.axisLabel.rotate': 35,
        series: {
          label: {
            normal: {
              show: true
            }
          }
        }
      },
      chartData: {
        columns: ['date', 'storage', 'rent'],
        rows: []
      },
      locationData: {
        zoom: 7,
        center: { lat: 23.854648, lng: 120.937847 }
      },
      markList: [],
      infoWinOpen: false,
      infoContent: '',
      currentMidx: null,
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      }
    }
  },
  mounted: function () {
    this.getMainData()
    // v-chart RWD
    const contall = this.$refs.chartWrap
    const chart = this.$refs.chart
    const erd = elementResizeDetectorMaker({
      strategy: 'scroll'
    })
    erd.listenTo(contall, function() {
      chart.echarts.resize()
    })
  },
  methods: {
    getMainData () {
      this.$store.dispatch('getMainData').then(res => {
        this.mainData = res[0]
        this.chartData.rows = []
        this.markList = []
        for (let i in res[1]) {
          this.chartData.rows.push(res[1][i])
        }
        for (let i in res[2]) {
          let status = (res[2][i].frameStatus === 1 ? '停車' : '') + (res[2][i].frameStatus === 0 ? '無車' : '') + (res[2][i].status === 1 ? '上鎖' : '') + (res[2][i].status === 0 ? '無上鎖' : '')
          let marker = {
            type: 'postion',
            position: {
              lat: parseFloat(res[2][i].lat),
              lng: parseFloat(res[2][i].lng)
            },
            spaceName: res[2][i].spaceName + '(' + status + ')',
            visible: true
          }
          this.markList.push(marker)
        }
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    toggleInfoWindow (marker, idx) {
      this.infoWindowPos = marker.position
      this.infoContent = marker.spaceName

      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else { // if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    }
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
