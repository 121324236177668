<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary" >
          <div slot="header">
            <i class="icon-list" style="margin-right: .5rem;"></i>
            <strong>車鎖管理</strong>
          </div>
          <b-row>
            <b-col cols="8" sm="8" md="10">
              <b-select v-model="searchData" :options="distributorOptions" @change="getLockData()"></b-select>
            </b-col>
            <b-col cols="4" sm="4" md="2" class="text-center">

            </b-col>
          </b-row>
          <hr>
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <div slot="lockType" slot-scope="props">
                <font v-if="props.row.lockType === 0">未知</font>
                <font v-if="props.row.lockType === 1">單車鎖</font>
                <font v-if="props.row.lockType === 2">機車鎖</font>
              </div>
              <div slot="frameStatus" slot-scope="props">
                <font v-if="props.row.frameStatus === 0">無車</font>
                <font v-if="props.row.frameStatus === 1">有車</font>
                <font v-if="props.row.frameStatus === 99">未知</font>
              </div>
              <div slot="status" slot-scope="props">
                <font v-if="props.row.status === 0">無上鎖</font>
                <font v-if="props.row.status === 1">上鎖</font>
                <font v-if="props.row.status === 99">未知</font>
              </div>
              <div slot="action" class="text-center" slot-scope="props">
                <!-- <b-button class="action-btn" variant="outline-primary" @click='info(props.row)'>詳細資料</b-button> -->
                <b-button class="action-btn" variant="outline-warning" @click="editLock(props.row)">編輯</b-button>
              </div>
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal v-model="editLockModal" title="編輯車鎖" header-bg-variant="warning" ok-only>
      <b-form-group
        label="車鎖ID"
        label-for="lockId"
        :label-cols="3">
        <b-form-input id="lockId" type="text" v-model="editData.lockId" trim placeholder="- lockId" plaintext></b-form-input>
      </b-form-group>
      <b-form-group
        label="車鎖類型"
        label-for="lockType"
        :label-cols="3">
        <b-form-select id="lockType" v-model="editData.lockType" :options="lockTypeOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="歸屬廠商"
        label-for="did"
        :label-cols="3">
        <b-form-select id="did" v-model="editData.did" :options="distributorOptions"></b-form-select>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="editLockModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="warning" @click="updateLock()">確定</b-button>
    </b-modal>

  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: .25rem!important;
}
.VueTables__limit-field select {
  margin-left: .25rem!important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
export default {
  name: 'space',
  components: {
  },
  data: function () {
    return {
      searchData: '',
      distributorOptions: [{value: '', text: '請選擇....', disabled: true}, {value: null, text: '無歸屬廠商'}],
      lockTypeOptions: [
        {value: 0, text: '未知'},
        {value: 1, text: '單車鎖'},
        {value: 2, text: '機車鎖'}
      ],
      editLockModal: false,
      editData: {},
      columns: [ 'lockId', 'lockType', 'frameStatus', 'status', 'batteryVoltage', 'action' ],
      data: [],
      options: {
        headings: {
          lockId: '車鎖ID',
          lockType: '車鎖類型',
          frameStatus: '車架狀態',
          status: '車鎖狀態',
          batteryVoltage: '電池電壓',
          action: '動作'
        },
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: [ 'lockId', 'lockType', 'frameStatus', 'status', 'batteryVoltage' ],
        filterable: [ 'lockId', 'lockType', 'frameStatus', 'status', 'batteryVoltage' ]
      }
    }
  },
  computed: {
  },
  mounted: function () {
    this.getDistributorData()
  },
  methods: {
    getDistributorData () {
      this.$store.dispatch('getDistributorData').then(res => {
        for (let i in res) {
          this.distributorOptions.push({
            value: res[i].did,
            text: res[i].distributorName
          })
        }
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    getLockData () {
      this.$store.dispatch('getLockData', {did: this.searchData}).then(res => {
        this.data = res
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    editLock (prop) {
      this.editData = { lpid: prop.lpid, lockId: prop.lockId, did: prop.did, lockType: prop.lockType }
      this.editLockModal = true
    },
    updateLock () {
      this.$store.dispatch('updateLock', this.editData).then(res => {
        this.editLockModal = false
        this.getLockData()
        this.$swal('Success', '車鎖更新成功', 'success')
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    }
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
