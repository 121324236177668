import request from '@/utils/request'
import sha256 from 'sha256'

export function loginByUsername (username, password) {
  if (password.length > 0) password = sha256(password)
  const data = {
    account: username,
    passwd: password,
    deviceType: 'browser'
  }
  return request({
    url: '/admin/login',
    method: 'post',
    data
  })
}

export function logout () {
  return request({
    url: '/admin/logout',
    method: 'post'
  })
}

export function getUserRoles () {
  return request({
    url: '/admin/roles',
    method: 'get'
  })
}

export function getUserData (condition) {
  return request({
    url: '/admin/userData',
    method: 'get',
    params: condition
  })
}

export function storeUser (createData) {
  createData.passwd = sha256(createData.passwd)
  const data = {
    createData
  }
  return request({
    url: '/admin/storeUser',
    method: 'post',
    data
  })
}

export function updateUser (editData) {
  if (editData.passwd) editData.passwd = sha256(editData.passwd)
  const data = {
    editData
  }
  return request({
    url: '/admin/updateUser',
    method: 'post',
    data
  })
}

export function destroyUser (deleteData) {
  const data = {
    deleteData
  }
  return request({
    url: '/admin/destroyUser',
    method: 'post',
    data
  })
}

export function getPersonalInfo () {
  return request({
    url: '/admin/personalInfo',
    method: 'get'
  })
}

export function updateDisplayName (editData) {
  const data = {
    editData
  }
  return request({
    url: '/admin/updateDisplayName',
    method: 'post',
    data
  })
}

export function resetNewPassword (editData) {
  if (editData.passwd) editData.passwd = sha256(editData.passwd)
  const data = {
    editData
  }
  return request({
    url: '/admin/resetNewPassword',
    method: 'post',
    data
  })
}
