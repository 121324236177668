import * as lockApi from '@/api/lock'

const lock = {
  actions: {
    getLockData ({ commit }, did) {
      return new Promise((resolve, reject) => {
        lockApi.getLockData(did).then(res => {
          if (res.code === 2001) {
            resolve(res.data)
          } else {
            reject(res.code)
          }
        })
      })
    },

    updateLock ({commit}, editData) {
      return new Promise((resolve, reject) => {
        lockApi.updateLock(editData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    }

  }
}

export default lock
