<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
      <b-card-group columns>
        <b-card>
          <b-row>
            <b-col>
              <div class="alignCenter">
                <b-img id="circleImg" v-bind="mainProps" rounded="circle" alt="Circle image" style="width: 30%;"></b-img>
                <br>
                <b-button variant="outline-primary" size="sm">變更個人圖片</b-button>
              </div>
              <hr>
              <b-col md="10" class="marginAuto">
                <b-form-group
                  label="暱稱"
                  label-for="displayName"
                  :label-cols="4"
                  >
                  <b-input-group>
                    <b-form-input id="displayName" v-model="displayName.value" type="text" placeholder="Name" :plaintext="displayName.plaintext"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="outline-primary" @click="editUsernameEvent()" v-show="!displayName.editable">
                        <i class="icon-pencil"></i>
                      </b-button>
                      <b-button variant="outline-primary" @click="saveUsernameEvent()" v-show="displayName.editable">
                        <i class="icon-check"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  label="帳號"
                  label-for="account"
                  :label-cols="4"
                  >
                  <b-input-group>
                    <b-form-input id="account" v-model="account"  type="text" placeholder="Username" plaintext></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  label="權限"
                  label-for="roles"
                  :label-cols="4"
                  >
                  <b-input-group>
                    <b-form-input id="roles" v-if="roles === 'admin'" v-model="roles" type="text" placeholder="roles" plaintext></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col md="11" class="marginAuto">
              <b-form-group
                label="變更密碼"
                label-for="password"
                :label-cols="4"
                >
                <b-form-input id="password" type="password" placeholder="new password" v-model="password"></b-form-input>
              </b-form-group>
              <b-form-group
                label="確認密碼"
                label-for="comfirmPassword"
                :label-cols="4"
                >
                <b-form-input id="comfirmPassword" type="password" placeholder="confirm new password" v-model="confirm_password"></b-form-input>
              </b-form-group>
              <hr>
              <b-button variant="primary" @click="resetPasswordEvent()">確定</b-button>&nbsp;
              <b-button variant="secondary" @click="clearResetPasswordEvent()">清除</b-button>
            </b-col>
          </b-row>
        </b-card>
        <!-- <b-card>
          <b-row>
            <b-col md="11" class="marginAuto">
              <b-form-group
                label="系統版本"
                label-for="systemVersion"
                :label-cols="5"
                >
                <b-form-input id="systemVersion" type="text" placeholder="10001010010" plaintext></b-form-input>
              </b-form-group>
              <b-form-group
                label="瀏覽器版本"
                label-for="browserVersion"
                :label-cols="5"
                >
                <b-form-input id="browserVersion" type="text" placeholder="Chrome 76.0.3809.100" plaintext></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card> -->
      </b-card-group>
      </b-col>
    </b-row>

  </div>
</template>

<style>
.marginAuto {
  margin: auto;
}
.alignCenter {
  text-align: center;
}
#userForm {
  margin-top: 1rem;
}
#circleImg {
  margin: 0.5rem auto;
}
#cancelPassword {
  margin-left: 0.5rem;
}
</style>

<script>
export default {
  name: 'personal',
  components: {
  },
  data: function () {
    return {
      mainProps: { src: 'img/avatars/5.png', class: 'm1' },
      displayName: {
        value: '',
        plaintext: true,
        editable: false
      },
      roles: '',
      account: '',
      password: '',
      confirm_password: '',
      auid: ''
    }
  },
  mounted () {
    this.getPersonalInfo()
  },
  methods: {
    getPersonalInfo () {
      this.$store.dispatch('getPersonalInfo').then((res) => {
        this.displayName.value = res[0].displayName
        this.account = res[0].account
        this.roles = res[0].roles
        this.auid = res[0].auid
      }).catch((e) => {
        this.$swal('Error', e, 'error')
      })
    },
    resetPasswordEvent () {
      if (this.password && this.confirm_password && this.password === this.confirm_password) {
        this.$store.dispatch('resetNewPassword', {auid: this.auid, passwd: this.password}).then((res) => {
          this.password = ''
          this.confirm_password = ''
          this.$swal('Success', '密碼更新成功', 'success')
        }).catch((e) => {
          this.$swal('Error', e, 'error')
        })
      } else {
        this.$swal('Warning', '輸入的密碼不符', 'warning')
      }
    },
    clearResetPasswordEvent () {
      this.password = ''
      this.confirm_password = ''
    },
    editUsernameEvent () {
      this.displayName.plaintext = false
      this.displayName.editable = true
    },
    saveUsernameEvent () {
      this.$store.dispatch('updateDisplayName', {auid: this.auid, displayName: this.displayName.value}).then((res) => {
        this.displayName.plaintext = true
        this.displayName.editable = false
        this.$swal('Success', '暱稱更新成功', 'success')
      }).catch((e) => {
        this.$swal('Error', e, 'error')
      })
    }
  }
}
</script>
