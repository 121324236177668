<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary" >
          <div slot="header">
            <i class="icon-list" style="margin-right: .5rem;"></i>
            <strong>車位管理</strong>
          </div>
          <b-row>
            <b-col cols="8" sm="8" md="10">
              <b-select v-model="searchData" :options="distributorOptions" @change="getSpaceData()"></b-select>
            </b-col>
            <b-col cols="4" sm="4" md="2" class="text-center">
              <b-button class="action-btn" variant="primary" @click="createSpace()">新增車位</b-button>
            </b-col>
          </b-row>
          <hr>
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <div slot="isEnabled" slot-scope="props">
                <p v-if="props.row.isEnabled === 1">啟用</p>
                <p v-if="props.row.isEnabled === 0">停用</p>
              </div>
              <div slot="action" class="text-center" slot-scope="props">
                <b-button class="action-btn" variant="outline-success" @click='location(props.row)'>打點</b-button>
                <b-button class="action-btn" variant="outline-warning" @click="editSpace(props.row)">編輯</b-button>
                <b-button class="action-btn" variant="outline-danger" disabled v-if="props.row.lpid !== null || props.row.isEnabled !== 0">刪除</b-button>
                <b-button class="action-btn" variant="outline-danger" @click="deleteSpace(props.row)" v-if="props.row.lpid === null && props.row.isEnabled === 0">刪除</b-button>
              </div>
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal v-model="createSpaceModal" title="新增車位" header-bg-variant="primary" ok-only no-stacking>
      <b-form-group
        label="廠商"
        label-for="did"
        :label-cols="3"
        :invalid-feedback="invalidDid"
        :state="stateDid">
        <b-form-select id="did" type="text" v-model="createData.did" :state="stateDid" trim placeholder="- did" :options="distributorOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="車位名稱"
        label-for="spaceName"
        :label-cols="3"
        :invalid-feedback="invalidSpaceName"
        :state="stateSpaceName">
        <b-form-input id="spaceName" type="text" v-model="createData.spaceName" :state="stateSpaceName" trim placeholder="- spaceName"></b-form-input>
      </b-form-group>
      <b-form-group
        label="使否啟用"
        label-for="isEnabled"
        :label-cols="3"
        :state="stateIsEnabled">
        <b-form-select id="isEnabled" v-model="createData.isEnabled" :state="stateIsEnabled" :options="isEnabledOptions"></b-form-select>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="createSpaceModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="storeSpace()">確定</b-button>
    </b-modal>

    <b-modal v-model="editSpaceModal" title="編輯車位" header-bg-variant="warning" ok-only>
      <b-form-group
        label="車位名稱"
        label-for="spaceName"
        :label-cols="3">
        <b-form-input id="spaceName" type="text" v-model="editData.spaceName" trim placeholder="- spaceName"></b-form-input>
      </b-form-group>
      <b-form-group
        label="使否啟用"
        label-for="isEnabled"
        :label-cols="3">
        <b-form-select id="isEnabled" v-model="editData.isEnabled" :options="isEnabledOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="綁定車鎖ID"
        label-for="lockId"
        :label-cols="3">
        <b-input-group>
          <b-form-input id="lockId" type="text" v-model="editData.lockId" trim placeholder="- 未綁定" plaintext></b-form-input>
          <b-input-group-append v-if="editData.isEnabled === 1">
            <b-button v-if="editData.lpid === null" variant="outline-primary" @click="bindList()">
              <i class="fa fa-lock"></i>
            </b-button>
            <b-button v-if="editData.lpid !== null" variant="outline-primary" @click="unbind()">
              <i class="fa fa-unlock"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="editSpaceModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="warning" @click="updateSpace()">確定</b-button>
    </b-modal>

    <b-modal v-model="bindListModal" title="鎖樁清單" header-bg-variant="primary" hide-footer ok-only on-stacking>
      <h6>未綁定清單</h6>
      <hr>
      <v-client-table :columns="bindListColumns" :data="bindListData" :options="bindListOptions">
        <div slot="lockId" slot-scope="props" @click="bind(props.row)">
          {{ props.row.lockId }}
        </div>
      </v-client-table>
    </b-modal>

    <b-modal v-model="deleteSpaceModal" title="刪除車位" header-bg-variant="danger" ok-only no-stacking>
      <h6>您確定要刪除 <font v-text="deleteData.spaceName"></font> ?</h6>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="deleteSpaceModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="danger" @click="destroySpace()">確定</b-button>
    </b-modal>

    <b-modal v-model="locationModal" title="打點設定" header-bg-variant="success" size="lg">
      <GmapMap class="map setLocationMap" :center="locationData.center" :zoom="locationData.zoom" @click="markLocation" style="width: 100%; height: 400px;">
        <GmapMarker v-for="mark in markList" :key="mark.pssID" :position="mark" icon="https://maps.google.com/mapfiles/ms/micons/blue.png"/>
        <GmapMarker :position="locationData.newMark" icon="https://maps.google.com/mapfiles/ms/micons/red-dot.png"/>
      </GmapMap>
      <b-form-group
        label="站點名稱"
        label-for="locationData.spaceName"
        :label-cols="3">
        <b-form-input id="spaceName" type="text" v-model="locationData.spaceName" trim placeholder="- spaceName" plaintext></b-form-input>
      </b-form-group>
      <b-form-group
        label="舊座標"
        label-for="oldLocation"
        :label-cols="3">
        <b-form-input id="oldLocation" type="text" v-model="locationData.oldLocation" trim placeholder="- oldLocation" plaintext></b-form-input>
      </b-form-group>
      <b-form-group
        label="新座標"
        label-for="newLocation"
        :label-cols="3">
        <b-form-input id="newLocation" type="text" v-model="locationData.newLocation" trim placeholder="- newLocation" plaintext></b-form-input>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="locationModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="setSpaceLocation()">確定</b-button>
    </b-modal>

  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: .25rem!important;
}
.VueTables__limit-field select {
  margin-left: .25rem!important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
export default {
  name: 'space',
  components: {
  },
  data: function () {
    return {
      searchData: '',
      distributorOptions: [{value: '', text: '請選擇....', disabled: true}],
      isEnabledOptions: [
        { value: 1, text: '啟用' },
        { value: 0, text: '停用' }
      ],
      createSpaceModal: false,
      createData: { did: '', spaceName: '', isEnabled: 1 },
      editSpaceModal: false,
      editData: {},
      deleteSpaceModal: false,
      deleteData: {},
      markList: [],
      locationModal: false,
      locationData: { zoom: 7, center: { lat: 23.854648, lng: 120.937847 }, lssid: '', spaceName: '', oldLocation: '', newLocation: '', newMark: null },
      columns: [ 'spaceName', 'lockId', 'isEnabled', 'action' ],
      data: [],
      options: {
        headings: {
          spaceName: '車位名稱',
          lockId: '車鎖ID',
          isEnabled: '是否啟用',
          action: '動作'
        },
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: [ 'spaceName', 'lockId', 'isEnabled' ],
        filterable: [ 'spaceName', 'lockId', 'isEnabled' ]
      },
      bindListModal: false,
      bindListColumns: [ 'lockId' ],
      bindListData: [],
      bindListOptions: {
        headings: {
          'lockId': '鎖樁ID'
        },
        filterable: [ 'lockId' ]
      }
    }
  },
  computed: {
    stateDid () {
      return this.createData.did.length > 0
    },
    invalidDid () {
      return this.createData.did.length > 0 ? '' : '必填欄位不得為空'
    },
    stateSpaceName () {
      return this.createData.spaceName.length > 0
    },
    invalidSpaceName () {
      return this.createData.spaceName.length > 0 ? '' : '必填欄位不得為空'
    },
    stateIsEnabled () {
      return this.createData.isEnabled !== null
    }
  },
  mounted: function () {
    this.getDistributorData()
  },
  methods: {
    getDistributorData () {
      this.$store.dispatch('getDistributorData').then(res => {
        for (let i in res) {
          this.distributorOptions.push({
            value: [res[i].did, {zoom: res[i].zoom, lat: res[i].lat, lng: res[i].lng}],
            text: res[i].distributorName
          })
        }
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    getSpaceData () {
      this.$store.dispatch('getSpaceData', {did: this.searchData[0]}).then(res => {
        this.data = res
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    createSpace () {
      this.createData = { did: '', spaceName: '', isEnabled: 1 }
      this.createSpaceModal = true
    },
    storeSpace () {
      if (this.stateDid && this.stateSpaceName && this.stateIsEnabled) {
        this.createData.did = this.createData.did[0]
        this.$store.dispatch('storeSpace', this.createData).then(res => {
          this.createSpaceModal = false
          if (this.createData.did === this.searchData[0]) this.getSpaceData()
          this.$swal('Success', '車位新增成功', 'success')
        }).catch(e => {
          this.$swal('Error', e, 'error')
        })
      }
    },
    editSpace (prop) {
      this.editData = { lssid: prop.lssid, spaceName: prop.spaceName, isEnabled: prop.isEnabled, lpid: prop.lpid, lockId: prop.lockId }
      this.editSpaceModal = true
    },
    updateSpace () {
      if (this.editData.spaceName.length > 0) {
        this.$store.dispatch('updateSpace', this.editData).then(res => {
          this.editSpaceModal = false
          this.getSpaceData()
          this.$swal('Success', '車位更新成功', 'success')
        }).catch(e => {
          this.$swal('Error', e, 'error')
        })
      }
    },
    bindList () {
      this.bindListData = []
      this.$store.dispatch('getBindList', {did: this.searchData[0]}).then(res => {
        for (let i in res) {
          if (res[i].binded === 0) {
            this.bindListData.push(res[i])
          }
        }
        this.bindListModal = true
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    bind (e) {
      this.editData.lpid = e.lpid
      this.editData.lockId = e.lockId
      this.bindListModal = false
    },
    unbind () {
      this.editData.lpid = null
      this.editData.lockId = null
    },
    deleteSpace (prop) {
      this.deleteData = prop
      this.deleteSpaceModal = true
    },
    destroySpace () {
      this.$store.dispatch('destroySpace', {lssid: this.deleteData.lssid}).then(res => {
        this.deleteSpaceModal = false
        this.getSpaceData()
        this.$swal('Success', '車位刪除成功', 'success')
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    location (prop) {
      this.markList = []
      for (let i in this.data) {
        if (prop.lssid !== this.data[i].lssid) {
          this.markList.push({
            lssid: this.data[i].lssid,
            lat: parseFloat(this.data[i].lat),
            lng: parseFloat(this.data[i].lng)
          })
        }
      }
      this.locationData = { zoom: 7, center: { lat: 23.854648, lng: 120.937847 }, lssid: '', spaceName: '', oldLocation: '', newLocation: '', newMark: null }
      this.locationData.spaceName = prop.spaceName
      this.locationData.lssid = prop.lssid
      if (prop.lat !== null && prop.lng !== null && prop.lat !== '' && prop.lng !== '') {
        this.locationData.newMark = { lat: parseFloat(prop.lat), lng: parseFloat(prop.lng) }
        this.locationData.oldLocation = parseFloat(prop.lat) + ', ' + parseFloat(prop.lng)
      }
      if (this.searchData[1].lat !== null && this.searchData[1].lng !== null && this.searchData[1].lat !== '' && this.searchData[1].lng !== '') {
        this.locationData.center = {lat: parseFloat(this.searchData[1].lat), lng: parseFloat(this.searchData[1].lng)}
      }
      if (this.searchData[1].zoom !== null) {
        this.locationData.zoom = this.searchData[1].zoom
      }
      this.locationModal = true
    },
    markLocation (e) {
      this.locationData.newMark = { lat: e.latLng.lat(), lng: e.latLng.lng() }
      this.locationData.newLocation = e.latLng.lat() + ', ' + e.latLng.lng()
    },
    setSpaceLocation () {
      this.$store.dispatch('setSpaceLocation', { lssid: this.locationData.lssid, lat: this.locationData.newMark.lat, lng: this.locationData.newMark.lng }).then(res => {
        this.locationModal = false
        this.getSpaceData()
        this.$swal('Success', '站點打點成功', 'success')
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    }
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
