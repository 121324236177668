import * as adminApi from '@/api/admin'
import * as authApi from '@/utils/auth'

const admin = {
  state: {
    user: '',
    status: '',
    code: '',
    token: authApi.getToken(),
    name: '',
    avatar: '',
    introduction: '',
    vendor: '',
    roles: '',
    setting: {
      articlePlatform: []
    }
  },

  mutations: {
    SET_CODE: (state, code) => {
      state.code = code
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
      state.introduction = introduction
    },
    SET_SETTING: (state, setting) => {
      state.setting = setting
    },
    SET_STATUS: (state, status) => {
      state.status = status
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_VENDOR: (state, vendor) => {
      state.vendor = vendor
    }
  },

  actions: {
    // 用户名登录
    LoginByUsername ({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password.trim()
      return new Promise((resolve, reject) => {
        adminApi.loginByUsername(username, password).then(response => {
          if (response.code === 2000) {
            commit('SET_TOKEN', response.data[0].token)
            authApi.setToken(response.data[0].token)
            resolve()
          } else {
            reject(response.message)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetUserRoles ({ commit }) {
      return new Promise((resolve, reject) => {
        adminApi.getUserRoles().then(response => {
          if (!response.data) { // 由于mockjs 不支持自定义状态码只能这样hack
            let e = 'error'
            reject(e)
          }
          const data = response.data[0]
          if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', data.roles)
          } else {
            let e = 'getInfo: roles must be a non-null array !'
            reject(e)
          }

          // commit('SET_NAME', data.name)
          // commit('SET_AVATAR', data.avatar)
          // commit('SET_INTRODUCTION', data.introduction)
          commit('SET_VENDOR', data.vendor)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 第三方验证登录
    // LoginByThirdparty({ commit, state }, code) {
    //   return new Promise((resolve, reject) => {
    //     commit('SET_CODE', code)
    //     loginByThirdparty(state.status, state.email, state.code).then(response => {
    //       commit('SET_TOKEN', response.data.token)
    //       setToken(response.data.token)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 登出
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        adminApi.logout(state.token).then((response) => {
          if (response.code === 2000) {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', '')
            commit('SET_VENDOR', '')
            authApi.removeToken()
            resolve(response)
          } else {
            reject(response.message)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', '')
        commit('SET_VENDOR', '')
        authApi.removeToken()
        resolve()
      })
    },

    getUserData ({ commit }, did) {
      return new Promise((resolve, reject) => {
        adminApi.getUserData(did).then(res => {
          if (res.code === 2001) {
            resolve(res.data)
          } else {
            reject(res.code)
          }
        })
      })
    },

    storeUser ({ commit }, createData) {
      return new Promise((resolve, reject) => {
        adminApi.storeUser(createData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    },

    updateUser ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        adminApi.updateUser(editData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    },

    destroyUser ({ commit }, deleteData) {
      return new Promise((resolve, reject) => {
        adminApi.destroyUser(deleteData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    },

    getPersonalInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        adminApi.getPersonalInfo().then(res => {
          if (res.code === 2001) {
            resolve(res.data)
          } else {
            reject(res.code)
          }
        })
      })
    },

    updateDisplayName ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        adminApi.updateDisplayName(editData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    },

    resetNewPassword ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        adminApi.resetNewPassword(editData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    }
  }
}

export default admin
