import * as indexApi from '@/api/index'

const index = {
  actions: {
    getMainData ({ commit }) {
      return new Promise((resolve, reject) => {
        indexApi.getMainData().then(res => {
          if (res.code === 2001) {
            resolve(res.data)
          } else {
            reject(res.code)
          }
        })
      })
    }

  }
}

export default index
