import request from '@/utils/request'

export function getSpaceData (condition) {
  return request({
    url: '/space/spaceData',
    method: 'get',
    params: condition
  })
}

export function getBindList (condition) {
  return request({
    url: '/space/bindList',
    method: 'get',
    params: condition
  })
}

export function storeSpace (createData) {
  const data = {
    createData
  }
  return request({
    url: '/space/storeSpace',
    method: 'post', 
    data
  })
}

export function updateSpace (editData) {
  const data = {
    editData
  }
  return request({
    url: '/space/updateSpace',
    method: 'post', 
    data
  })
}

export function destroySpace (deleteData) {
  const data = {
    deleteData
  }
  return request({
    url: '/space/destroySpace',
    method: 'post', 
    data
  })
}

export function setSpaceLocation (locationData) {
  const data = {
    locationData
  }
  return request({
    url: '/space/setSpaceLocation',
    method: 'post', 
    data
  })
}
