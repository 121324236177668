import Vue from 'vue'
import Vuex from 'vuex'
import getters from '@/store/getters'
import admin from '@/store/modules/admin'
import index from '@/store/modules/index'
import distributor from '@/store/modules/distributor'
import space from '@/store/modules/space'
import lock from '@/store/modules/lock'
import node from '@/store/modules/node'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin,
    index,
    distributor,
    space,
    lock,
    node
  },
  getters
})
