<template>
  <div class="animated fadeIn">
    <b-card class="card-accent-info">
      <b-row>
        <b-col cols="8" sm="9" md="10"></b-col>
        <b-col cols="4" sm="3" md="2" class="text-center">
          <b-button class="action-btn" variant="primary" @click="createDistributor()">新增車位</b-button>
        </b-col>
      </b-row>
      <hr>
      <div>
        <v-client-table :columns="columns" :data="data" :options="options">
          <div slot="isEnabled" slot-scope="props">
            <p v-if="props.row.isEnabled === 1">啟用</p>
            <p v-if="props.row.isEnabled === 0">停用</p>
          </div>
          <div slot="action" class="text-center" slot-scope="props">
            <b-button class="action-btn" variant="outline-primary" @click='sdk(props.row)'>SDK</b-button>
            <b-button class="action-btn" variant="outline-success" @click='location(props.row)'>打點</b-button>
            <b-button class="action-btn" variant="outline-warning" @click="editDistributor(props.row)">編輯</b-button>
            <b-button class="action-btn" variant="outline-danger" disabled v-if="props.row.userCount !== 0 || props.row.lockCount !== 0 || props.row.spaceCount !== 0 || props.row.sdkCount !== 0">刪除</b-button>
            <b-button class="action-btn" variant="outline-danger" @click="deleteDistributor(props.row)" v-if="props.row.userCount === 0 && props.row.lockCount === 0 && props.row.spaceCount === 0 && props.row.sdkCount === 0">刪除</b-button>
          </div>
        </v-client-table>
      </div>
    </b-card>

    <b-modal v-model="createDistributorModal" title="新增廠商" header-bg-variant="primary" no-stacking>
      <b-form-group
        label="廠商名稱"
        label-for="distributorName"
        :label-cols="3"
        :invalid-feedback="invalidDistributorName"
        :state="stateDistributorName">
        <b-form-input id="distributorName" type="text" v-model="createData.distributorName" :state="stateDistributorName" trim placeholder="- distributorName"></b-form-input>
      </b-form-group>
      <b-form-group
        label="是否啟用"
        label-for="isEnabled"
        :label-cols="3"
        :state="stateIsEnabled">
        <b-form-select id="isEnabled" v-model="createData.isEnabled" :state="stateIsEnabled" :options="isEnabledOptions"></b-form-select>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="createDistributorModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="storeDistributor()">確定</b-button>
    </b-modal>

    <b-modal v-model="editDistributorModal" title="編輯廠商" header-bg-variant="warning" no-stacking>
      <b-form-group
        label="廠商名稱"
        label-for="distributorName"
        :label-cols="3">
        <b-form-input id="distributorName" type="text" v-model="editData.distributorName" trim placeholder="- distributorName"></b-form-input>
      </b-form-group>
      <b-form-group
        label="是否啟用"
        label-for="isEnabled"
        :label-cols="3">
        <b-form-select id="isEnabled" v-model="editData.isEnabled" :options="isEnabledOptions"></b-form-select>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="editDistributorModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="updateDistributor()">確定</b-button>
    </b-modal>

    <b-modal v-model="deleteDistributorModal" title="刪除廠商" header-bg-variant="danger" no-stacking>
      <h6>您確定要刪除 <font v-text="deleteData.distributorName"></font> ?</h6>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="deleteDistributorModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="destroyDistributor()">確定</b-button>
    </b-modal>

    <b-modal v-model="locationModal" title="打點設定" header-bg-variant="success" size="lg">
      <GmapMap class="map setLocationMap" :center="locationData.center" :zoom="locationData.zoom" @click="markLocation" @zoom_changed="listenZoom" style="width: 100%; height: 400px;">
        <GmapMarker :position="locationData.mark" icon="https://maps.google.com/mapfiles/ms/micons/blue.png"/>
        <GmapMarker :position="locationData.newMark" icon="https://maps.google.com/mapfiles/ms/micons/red-dot.png"/>
      </GmapMap>
      <b-form-group
        label="廠商名稱"
        label-for="locationData.distributorName"
        :label-cols="3">
        <b-form-input id="distributorName" type="text" v-model="locationData.distributorName" trim placeholder="- distributorName" plaintext></b-form-input>
      </b-form-group>
      <b-form-group
        label="縮放比"
        label-for="zoom"
        :label-cols="3">
        <b-form-input id="zoom" type="text" v-model="locationData.zoom" trim placeholder="- zoom" plaintext></b-form-input>
      </b-form-group>
      <b-form-group
        label="舊座標"
        label-for="oldLocation"
        :label-cols="3">
        <b-form-input id="oldLocation" type="text" v-model="locationData.oldLocation" trim placeholder="- oldLocation" plaintext></b-form-input>
      </b-form-group>
      <b-form-group
        label="新座標"
        label-for="newLocation"
        :label-cols="3">
        <b-form-input id="newLocation" type="text" v-model="locationData.newLocation" trim placeholder="- newLocation" plaintext></b-form-input>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="locationModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="setDistributorLocation()">確定</b-button>
    </b-modal>

    <b-modal v-model="sdkModal" title="SDK管理" header-bg-variant="primary" hide-footer size="xl">
      <b-row>
        <b-col cols="8" sm="9" md="10" class="d-flex align-items-end">
          <h6>廠商名稱 : <font v-text="showSdkData.distributorName"></font></h6>
        </b-col>
        <b-col cols="4" sm="3" md="2" class="text-center">
          <b-button class="action-btn" variant="primary" @click="createSdk()">新增SDK</b-button>
        </b-col>
      </b-row>
      <hr>
      <v-client-table :columns="sdkColumns" :data="sdkData" :options="sdkOptions">
        <div slot="action" class="text-center" slot-scope="props">
          <b-button class="action-btn" variant="outline-warning" @click="editSdk(props.row)">編輯</b-button>
          <b-button class="action-btn" variant="outline-danger" @click="deleteSdk(props.row)">刪除</b-button>
        </div>
      </v-client-table>
    </b-modal>

    <b-modal v-model="createSdkModal" title="新增SDK" header-bg-variant="primary">
      <b-form-group
        label="廠商名稱"
        label-for="distributorName"
        :label-cols="3">
        <b-form-input id="distributorName" type="text" v-model="showSdkData.distributorName" trim placeholder="- distributorName" plaintext></b-form-input>
      </b-form-group>
      <hr>
      <b-form-group
        label="APP名稱"
        label-for="appName"
        :label-cols="3"
        :invalid-feedback="invalidAppName"
        :state="stateAppName">
        <b-form-input id="appName" type="text" v-model="createSdkData.appName" :state="stateAppName" trim placeholder="- appName"></b-form-input>
      </b-form-group>
      <b-form-group
        label="APP類型"
        label-for="appType"
        :label-cols="3"
        :state="stateAppType">
        <b-form-select id="appType" v-model="createSdkData.appType" :state="stateAppType" :options="appTypeOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="套件名稱"
        label-for="packageName"
        :label-cols="3"
        :invalid-feedback="invalidPackageName"
        :state="statePackageName">
        <b-form-input id="packageName" type="text" v-model="createSdkData.packageName" :state="statePackageName" trim placeholder="- packageName"></b-form-input>
      </b-form-group>
      <b-form-group
        label="測試用Key"
        label-for="androidTestKey"
        :label-cols="3"
        v-if="createSdkData.appType === 'android'">
        <b-form-input id="androidTestKey" type="text" v-model="createSdkData.androidTestKey" trim placeholder="- androidTestKey"></b-form-input>
      </b-form-group>
      <b-form-group
        label="發行用Key"
        label-for="androidReleaseKey"
        :label-cols="3"
        v-if="createSdkData.appType === 'android'">
        <b-form-input id="androidReleaseKey" type="text" v-model="createSdkData.androidReleaseKey" trim placeholder="- androidReleaseKey"></b-form-input>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="createSdkModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="storeSdk()">確定</b-button>
    </b-modal>

    <b-modal v-model="editSdkModal" title="編輯SDK" header-bg-variant="warning">
      <b-form-group
        label="廠商名稱"
        label-for="distributorName"
        :label-cols="3">
        <b-form-input id="distributorName" type="text" v-model="showSdkData.distributorName" trim placeholder="- distributorName" plaintext></b-form-input>
      </b-form-group>
      <hr>
      <b-form-group
        label="APP名稱"
        label-for="appName"
        :label-cols="3">
        <b-form-input id="appName" type="text" v-model="editSdkData.appName" trim placeholder="- appName"></b-form-input>
      </b-form-group>
      <b-form-group
        label="APP類型"
        label-for="appType"
        :label-cols="3">
        <b-form-select id="appType" v-model="editSdkData.appType" :options="appTypeOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="套件名稱"
        label-for="packageName"
        :label-cols="3">
        <b-form-input id="packageName" type="text" v-model="editSdkData.packageName" trim placeholder="- packageName"></b-form-input>
      </b-form-group>
      <b-form-group
        label="測試用Key"
        label-for="androidTestKey"
        :label-cols="3"
        v-if="editSdkData.appType === 'android'">
        <b-form-input id="androidTestKey" type="text" v-model="editSdkData.androidTestKey" trim placeholder="- androidTestKey"></b-form-input>
      </b-form-group>
      <b-form-group
        label="發行用Key"
        label-for="androidReleaseKey"
        :label-cols="3"
        v-if="editSdkData.appType === 'android'">
        <b-form-input id="androidReleaseKey" type="text" v-model="editSdkData.androidReleaseKey" trim placeholder="- androidReleaseKey"></b-form-input>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="editSdkModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="updateSdk()">確定</b-button>
    </b-modal>

    <b-modal v-model="deleteSdkModal" title="刪除SDK" header-bg-variant="danger">
      <h6>您確定要刪除 <font v-text="deleteSdkData.packageName"></font> ?</h6>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="deleteSdkModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="destroySdk()">確定</b-button>
    </b-modal>

  </div>
</template>
<style>

.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: .25rem!important;
}
.VueTables__limit-field select {
  margin-left: .25rem!important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
export default {
  name: 'distributor',
  components: {
  },
  data: function () {
    return {
      createSdkModal: false,
      createSdkData: {did: '', appName: '', appType: 'ios', packageName: '', androidTestKey: null, androidReleaseKey: null},
      appTypeOptions: [
        { value: 'ios', text: 'IOS' },
        { value: 'android', text: 'Android' }
      ],
      editSdkModal: false,
      editSdkData: {},
      deleteSdkModal: false,
      deleteSdkData: {},
      sdkModal: false,
      showSdkData: {did: '', distributorName: ''},
      sdkColumns: [ 'appName', 'appType', 'packageName', 'action' ],
      sdkData: [],
      sdkOptions: {
        headings: {
          appName: 'APP名稱',
          appType: 'APP類型',
          packageName: '套件名稱',
          action: '動作'
        },
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: [ 'appName', 'appType', 'packageName' ],
        filterable: [ 'appName', 'appType', 'packageName' ]
      },
      locationModal: false,
      locationData: {
        zoom: 7,
        center: { lat: 23.854648, lng: 120.937847 }
      },
      createDistributorModal: false,
      createData: {distributorName: '', isEnabled: 1},
      isEnabledOptions: [
        {value: 1, text: '啟用'},
        {value: 0, text: '停用'}
      ],
      editDistributorModal: false,
      editData: {},
      deleteDistributorModal: false,
      deleteData: {},
      columns: [ 'distributorName', 'isEnabled', 'action' ],
      data: [],
      options: {
        headings: {
          distributorName: '廠商名稱',
          isEnabled: '是否啟用',
          action: '動作'
        },
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: [ 'distributorName', 'isEnabled' ],
        filterable: [ 'distributorName', 'isEnabled' ]
      }
    }
  },
  computed: {
    stateDistributorName () {
      return this.createData.distributorName.length > 0
    },
    invalidDistributorName () {
      return this.createData.distributorName.length > 0 ? '' : '必填欄位不得為空'
    },
    stateIsEnabled () {
      return this.createData.isEnabled !== null
    },
    stateAppName () {
      return this.createSdkData.appName.length > 0
    },
    invalidAppName () {
      return this.createSdkData.appName.length > 0 ? '' : '必填欄位不得為空'
    },
    statePackageName () {
      return this.createSdkData.packageName.length > 0
    },
    invalidPackageName () {
      return this.createSdkData.packageName.length > 0 ? '' : '必填欄位不得為空'
    },
    stateAppType () {
      return this.createSdkData.appType !== null
    }
  },
  mounted: function () {
    this.getDistributorData()
  },
  methods: {
    getDistributorData () {
      this.$store.dispatch('getDistributorData').then(res => {
        this.data = res
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    createDistributor () {
      this.createData = {distributorName: '', isEnabled: 1}
      this.createDistributorModal = true
    },
    storeDistributor () {
      if (this.stateDistributorName && this.stateIsEnabled) {
        this.$store.dispatch('storeDistributor', this.createData).then(res => {
          this.createDistributorModal = false
          this.getDistributorData()
          this.$swal('Success', '廠商新增成功', 'success')
        }).catch(e => {
          this.$swal('Error', e, 'error')
        })
      }
    },
    editDistributor (prop) {
      this.editData = { distributorName: prop.distributorName, isEnabled: prop.isEnabled }
      this.editDistributorModal = true
    },
    updateDistributor () {
      this.$store.dispatch('updateDistributor', this.editData).then(res => {
        this.editDistributorModal = false
        this.getDistributorData()
        this.$swal('Success', '廠商更新成功', 'success')
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    deleteDistributor (prop) {
      this.deleteData = prop
      this.deleteDistributorModal = true
    },
    destroyDistributor() {
      this.$store.dispatch('destroyDistributor', this.deleteData).then(res => {
        this.deleteDistributorModal = false
        this.getDistributorData()
        this.$swal('Success', '廠商刪除成功', 'success')
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    location (prop) {
      this.locationData = {
        zoom: 7,
        center: { lat: 23.854648, lng: 120.937847 },
        distributorName: prop.distributorName,
        did: prop.did,
        mark: null,
        newMark: null,
        oldLocation: '',
        newLocation: ''
      }
      if (prop.lat && prop.lng) {
        this.locationData.mark = {lat: parseFloat(prop.lat), lng: parseFloat(prop.lng)}
        this.locationData.center = this.locationData.mark
        this.locationData.oldLocation = parseFloat(prop.lat) + ', ' + parseFloat(prop.lng)
      }
      if (prop.zoom) {
        this.locationData.zoom = prop.zoom
      }
      this.locationModal = true
    },
    markLocation (e) {
      this.locationData.newMark = { lat: e.latLng.lat(), lng: e.latLng.lng() }
      this.locationData.center = this.locationData.newMark
      this.locationData.newLocation = e.latLng.lat() + ', ' + e.latLng.lng()
    },
    listenZoom (e) {
      this.locationData.zoom = e
    },
    setDistributorLocation () {
      this.$store.dispatch('setDistributorLocation', this.locationData).then(res => {
        this.locationModal = false
        this.getDistributorData()
        this.$swal('Success', '廠商打點成功', 'success')
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    sdk (prop) {
      this.$store.dispatch('getSdkData', {did: prop.did}).then(res => {
        this.showSdkData.did = prop.did
        this.showSdkData.distributorName = prop.distributorName
        this.sdkData = res
        this.sdkModal = true
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    createSdk () {
      this.createSdkData = {did: this.showSdkData.did, appName: '', appType: 'ios', packageName: '', androidTestKey: null, androidReleaseKey: null}
      this.createSdkModal = true
    },
    storeSdk () {
      if (this.stateAppName && this.stateAppType && this.statePackageName) {
        if (this.createSdkData.appType === 'ios') {
          this.createSdkData.androidTestKey = null
          this.createSdkData.androidReleaseKey = null
        }
        this.$store.dispatch('storeSdk', this.createSdkData).then(res => {
          this.createSdkModal = false
          this.sdk(this.showSdkData)
          this.$swal('Success', 'SDK新增成功', 'success')
        }).catch(e => {
          this.$swal('Error', e, 'error')
        })
      }
    },
    editSdk (prop) {
      this.editSdkData = {dsid: prop.dsid, appName: prop.appName, appType: prop.appType, packageName: prop.packageName, androidTestKey: prop.androidTestKey, androidReleaseKey: prop.androidReleaseKey}
      this.editSdkModal = true
    },
    updateSdk () {
      if (this.editSdkData.appType === 'ios') {
        this.editSdkData.androidTestKey = null
        this.editSdkData.androidReleaseKey = null
      }
      this.$store.dispatch('updateSdk', this.editSdkData).then(res => {
        this.editSdkModal = false
        this.sdk(this.showSdkData)
        this.$swal('Success', 'SDK更新成功', 'success')
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    deleteSdk (prop) {
      this.deleteSdkData = prop
      this.deleteSdkModal = true
    },
    destroySdk () {
      this.$store.dispatch('destroySdk', this.deleteSdkData).then(res => {
        this.deleteSdkModal = false
        this.sdk(this.showSdkData)
        this.$swal('Success', 'SDK刪除成功', 'success')
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    }
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
