import * as distributorApi from '@/api/distributor'

const distributor = {
  actions: {
    getDistributorData ({ commit }) {
      return new Promise((resolve, reject) => {
        distributorApi.getDistributorData().then(res => {
          if (res.code === 2001) {
            resolve(res.data)
          } else {
            reject(res.code)
          }
        })
      })
    },

    storeDistributor ({ commit }, createData) {
      return new Promise((resolve, reject) => {
        distributorApi.storeDistributor(createData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    },

    updateDistributor ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        distributorApi.updateDistributor(editData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    },

    destroyDistributor ({ commit }, deleteData) {
      return new Promise((resolve, reject) => {
        distributorApi.destroyDistributor(deleteData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    },

    setDistributorLocation ({ commit }, locationData) {
      return new Promise((resolve, reject) => {
        distributorApi.setDistributorLocation(locationData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    },

    getSdkData ({ commit }, did) {
      return new Promise((resolve, reject) => {
        distributorApi.getSdkData(did).then(res => {
          if (res.code === 2001) {
            resolve(res.data)
          } else {
            reject(res.code)
          }
        })
      })
    },

    storeSdk ({ commit }, createSdkData) {
      return new Promise((resolve, reject) => {
        distributorApi.storeSdk(createSdkData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    },

    updateSdk ({ commit }, editSdkData) {
      return new Promise((resolve, reject) => {
        distributorApi.updateSdk(editSdkData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    },

    destroySdk ({ commit }, deleteSdkData) {
      return new Promise((resolve, reject) => {
        distributorApi.destroySdk(deleteSdkData).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.code)
          }
        })
      })
    }

  }
}

export default distributor
