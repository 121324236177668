<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary" >
          <div slot="header">
            <i class="icon-list" style="margin-right: .5rem;"></i>
            <strong>節點機管理</strong>
          </div>
          <!-- <b-row>
            <b-col cols="8" sm="8" md="10">
              
            </b-col>
            <b-col cols="4" sm="4" md="2" class="text-center">

            </b-col>
          </b-row>
          <hr> -->
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <div slot="action" class="text-center" slot-scope="props">
                <b-button class="action-btn" variant="outline-success" @click='location(props.row)'>打點</b-button>
                <b-button class="action-btn" variant="outline-warning" @click="editNode(props.row)">編輯</b-button>
              </div>
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal v-model="editNodeModal" title="編輯節點機" header-bg-variant="warning" ok-only>
      <b-form-group
        label="節點機ID"
        label-for="nodeId"
        :label-cols="3">
        <b-form-input id="nodeId" type="text" v-model="editData.nodeId" trim placeholder="- nodeId" plaintext></b-form-input>
      </b-form-group>
      <b-form-group
        label="節點機名稱"
        label-for="nodeName"
        :label-cols="3">
        <b-form-input id="nodeName" type="text" v-model="editData.nodeName" trim placeholder="- nodeName"></b-form-input>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="editNodeModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="warning" @click="updateNode()">確定</b-button>
    </b-modal>

    <b-modal v-model="locationModal" title="打點設定" header-bg-variant="success" size="lg">
      <GmapMap class="map setLocationMap" :center="locationData.center" :zoom="locationData.zoom" @click="markLocation" style="width: 100%; height: 400px;">
        <GmapMarker :position="locationData.newMark" icon="https://maps.google.com/mapfiles/ms/micons/red-dot.png"/>
      </GmapMap>
      <b-form-group
        label="節點機ID"
        label-for="nodeId"
        :label-cols="3">
        <b-form-input id="nodeId" type="text" v-model="locationData.nodeId" trim placeholder="- nodeId" plaintext></b-form-input>
      </b-form-group>
      <b-form-group
        label="舊座標"
        label-for="oldLocation"
        :label-cols="3">
        <b-form-input id="oldLocation" type="text" v-model="locationData.oldLocation" trim placeholder="- oldLocation" plaintext></b-form-input>
      </b-form-group>
      <b-form-group
        label="新座標"
        label-for="newLocation"
        :label-cols="3">
        <b-form-input id="newLocation" type="text" v-model="locationData.newLocation" trim placeholder="- newLocation" plaintext></b-form-input>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="locationModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="setNodeLocation()">確定</b-button>
    </b-modal>

  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: .25rem!important;
}
.VueTables__limit-field select {
  margin-left: .25rem!important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
export default {
  name: 'node',
  components: {
  },
  data: function () {
    return {
      locationModal: false,
      locationData: { zoom: 7, center: { lat: 23.854648, lng: 120.937847 }, nid: '', nodeId: '', oldLocation: '', newLocation: '', newMark: null },
      editNodeModal: false,
      editData: {},
      columns: [ 'nodeId', 'nodeName', 'version', 'battery', 'imsi', 'rsrp', 'action' ],
      data: [],
      options: {
        headings: {
          nodeId: '節點機ID',
          nodeName: '節點機名稱',
          version: '節點機版本',
          battery: '電池電量',
          imsi: 'IMSI',
          rsrp: '訊號品質',
          action: '動作'
        },
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        },
        sortable: [ 'nodeId', 'nodeName', 'version', 'battery', 'imsi', 'rsrp' ],
        filterable: [ 'nodeId', 'nodeName', 'version', 'battery', 'imsi', 'rsrp' ]
      }
    }
  },
  computed: {
  },
  mounted: function () {
    this.getNodeData()
  },
  methods: {
    getNodeData () {
      this.$store.dispatch('getNodeData').then(res => {
        this.data = res
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    editNode (prop) {
      this.editData = { nid: prop.nid, nodeId: prop.nodeId, nodeName: prop.nodeName }
      this.editNodeModal = true
    },
    updateNode () {
      this.$store.dispatch('updateNode', this.editData).then(res => {
        this.editNodeModal = false
        this.getNodeData()
        this.$swal('Success', '節點機更新成功', 'success')
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    location (prop) {
      this.locationData = { zoom: 7, center: { lat: 23.854648, lng: 120.937847 }, nid: '', nodeId: '', oldLocation: '', newLocation: '', newMark: null }
      this.locationData.nodeId = prop.nodeId
      this.locationData.nid = prop.nid
      if (prop.lat !== null && prop.lng !== null && prop.lat !== '' && prop.lng !== '') {
        this.locationData.newMark = { lat: parseFloat(prop.lat), lng: parseFloat(prop.lng) }
        this.locationData.oldLocation = parseFloat(prop.lat) + ', ' + parseFloat(prop.lng)
      }
      this.locationModal = true
    },
    markLocation (e) {
      this.locationData.newMark = { lat: e.latLng.lat(), lng: e.latLng.lng() }
      this.locationData.newLocation = e.latLng.lat() + ', ' + e.latLng.lng()
    },
    setNodeLocation () {
      this.$store.dispatch('setNodeLocation', { nid: this.locationData.nid, lat: this.locationData.newMark.lat, lng: this.locationData.newMark.lng }).then(res => {
        this.locationModal = false
        this.getNodeData()
        this.$swal('Success', '節點機打點成功', 'success')
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    }
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
